// Gatsby supports TypeScript natively!
import React from "react"
import {PageProps, Link} from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import kuratorium from "../images/kuratorium.jpg"
import beirat from "../images/beirat.jpg"

const Boards = (props: PageProps) => (
    <Layout>
        <SEO title="KPF.NRW Gremien"/>
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
              KPF.NRW
            </span>
                        <span
                            className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Kuratorium
            </span>
                    </h1>
                </div>
                <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        Das Kuratorium wurde von den Initiatoren der KPF.NRW – vom Ministerium für Kultur und
                        Wissenschaft des Landes Nordrhein-Westfalen gemeinsam mit den Landschaftsverbänden Rheinland und
                        Westfalen-Lippe als Steuergremium eingerichtet.
                    </p>
                    <img className="w-full rounded-lg" src={kuratorium}/>
                    <ul className="mt-8 text-xl text-gray-500 leading-8">
                        <li><b>Dr. Corinna Franz</b>, LVR-Dezernentin für Kultur und Landschaftliche Kulturpflege des
                            Landschaftsverbands Rheinland
                        </li>
                        <li><b>Dr. Hildegard Kaluza</b>, Leiterin der Kulturabteilung im Ministerium für Kultur und
                            Wissenschaft des Landes Nordrhein-Westfalen
                        </li>
                        <li><b>Dr. Barbara Rüschoff-Parzinger</b>, LWL-Kulturdezernentin des Landschaftsverbands
                            Westfalen-Lippe
                        </li>
                        <li><b>Prof. Dr. Christoph Zuschlag</b>, Alfried Krupp von Bohlen und Halbach-Professor für
                            Kunstgeschichte der Moderne und der Gegenwart (19.–21. Jh.) der Forschungsstelle
                            Provenienzforschung, Kunst- und Kulturgutschutzrecht Universität Bonn
                        </li>
                    </ul>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h1>
            <span
                className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Beirat
            </span>
                        </h1>
                    </div>
                    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                        <p className="mt-8 text-xl text-gray-500 leading-8">
                            Der Beirat begleitet und fördert die Tätigkeit der KPF.NRW aus fachlicher Perspektive. Zudem
                            besteht seine Aufgabe darin, auf Themen und Trends zu verweisen, welche von der
                            Öffentlichkeit, Wissenschaft und Politik als wesentlich empfunden werden.
                            <br/>
                            Dem Beirat gehören 13 Mitglieder an sowie deren Stellvertreter.

                        </p>
                        <img className="w-full rounded-lg" src={beirat}/>
                    </div>
                </div>
                <p>
                <br />
                </p>
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h2>
            <span
                className="mt-2 block text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
              Vorsitzende
            </span>
                        </h2>
                    </div>
                    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                    <ul className="mt-8 text-xl text-gray-500 leading-8">
                        <li><b>Dr. Uwe Hartmann</b>, Leiter des Fachbereichs Kulturgutverluste im 20. Jahrhundert in Europa,
                            Deutsches Zentrum Kulturgutverluste, Magdeburg
                        </li>
                        <li><b>Dr. Tanja Pirsig-Marshall</b>, Stellv. Direktorin des LWL-Museums für Kunst und Kultur,
                            Münster
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h2>
            <span
                className="mt-2 block text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
              Weitere Mitglieder
            </span>
                        </h2>
                    </div>
                    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                    <ul className="mt-8 text-xl text-gray-500 leading-8">
                        <li><b>Dr. Heike Baare</b>, Wiss. Referentin der LVR-Museumsberatung, Köln</li>
                        <li><b>Dr. Christina Berking</b>, Rechtsanwältin, Justiziarin der Interessengemeinschaft
                            Internationaler Kunsthandel, Hamburg
                        </li>
                        <li><b>Tilmann Bruhn</b>, Geschäftsführer des Museumsverbands NRW e.V., Dortmund</li>
                        <li><b>Eline van Dijk</b>, Wiss. Referentin für Provenienzforschung am LWL-Museum für Kunst und Kultur,
                            Münster
                        </li>
                        <li><b>Dr. Sabine Eibl</b>, Dezernentin des Landesarchivs NRW, Abteilung Rheinland, Dezernat 3 –
                            Verwaltungs-, Justiz- und Finanzbehörden, Duisburg
                        </li>
                        <li><b>Annika Flamm</b>, Wiss. Referentin der LVR-Museumsberatung, Köln</li>
                        <li><b>Dr. Larissa Förster</b>, Leiterin des Fachbereichs Kultur- und Sammlungsgut aus kolonialen
                            Kontexten, Deutsches Zentrum Kulturgutverluste, Berlin
                        </li>
                        <li><b>Dr. Ulrike Gärtner</b>, Provenienzforscherin am Museum für Kunst und Kulturgeschichte,
                            Dortmund
                        </li>
                        <li><b>Dr. Inna Goudz</b>, Geschäftsführerin des Landesverbandes der Jüdischen Gemeinden von Nordrhein
                            K.d.ö.R., Düsseldorf
                        </li>
                        <li><b>Dr. Michael Herkenhoff</b>, Leiter des Dezernats 5 – Handschriften, Altbestand, Fachreferate:
                            Geschichte, Ethnologie, Archäologie, Zeitungen, Universitäts- und Landesbibliothek Bonn
                        </li>
                        <li><b>Dr. Christiane Hoffrath</b>, Dezernentin Historische Bestände und Sammlungen, Bestandserhaltung
                            und Digitalisierung, Universitäts- und Stadtbibliothek Köln
                        </li>
                        <li><b>Dr. Oded Horowitz</b>, Vorstandsvorsitzender des Landesverbandes der Jüdischen Gemeinden von
                            Nordrhein K.d.ö.R., Düsseldorf
                        <li><b>Kirsten John-Stuck</b>, Leiterin des Kreismuseums Wewelsburg
                        </li>
                        <li><b>Dr. Tim Kohl</b>, Leiter des Referats Gedenkstättenförderung und Erinnerungskultur,
                            Landeszentrale für politische Bildung Nordrhein-Westfalen, Düsseldorf
                        </li>
                        <li><b>Birgit Lüke</b>, Referentin im Referat Gedenkstättenförderung und Erinnerungskultur,
                            Landeszentrale für politische Bildung Nordrhein-Westfalen, Düsseldorf
                        </li>
                        <li><b>apl. Prof. Dr. Nadine Oberste-Hetbleck</b>, Direktorin des Zentralarchivs für deutsche und
                            internationale Kunstmarktforschung, Köln
                        </li><b>Prof. Dr. Lynn Rother</b>, Lichtenberg-Professorin für Provenienzstudien und Leiterin Provenance Lab, Leuphana Universität Lüneburg
                        </li>
                        <li><b>Christina Stausberg</b>, Hauptreferentin für Kultur beim Deutschen Städtetag, Köln</li>
                        <li><b>Dr. Mark Steinert</b>, Leiter des LVR-Archivberatungs- und Fortbildungszentrums, Pulheim</li>
                        <li><b>Dr. Michaela Stoffels</b>, Referentin für Kultur und Bildung beim Deutschen Städtetag, Köln</li>
                        <li><b>Dr. Amir Theilhaber</b>, Projektleiter Provenienzforschung am Lippischen Landesmuseum Detmold
                        </li>
                        <li><b>Ruth Türnich</b>, Wiss. Referentin im Referat 423 Visuelle Künste, Provenienz im Ministerium für
                            Kultur und Wissenschaft, Düsseldorf
                        </li>
                        <li><b>Prof. Dr. jur. Matthias Weller</b>, Alfried Krupp von Bohlen und Halbach-Professor für
                            Bürgerliches Recht, Kunst- und Kulturgutschutzrecht der Forschungsstelle
                            Provenienzforschung, Kunst- und Kulturgutschutzrecht, Rheinische
                            Friedrich-Wilhelms-Universität Bonn
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default Boards
